window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});

// Adiciona a meta tag robots para evitar indexação
const metaTag = document.createElement('meta');
metaTag.name = "robots";
metaTag.content = "noindex, nofollow";
document.head.appendChild(metaTag);